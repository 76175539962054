import { NavLink } from "react-router-dom";
import { ROUTES } from "src/app/routing/routes";
import { TNavIcon } from "src/shared/types/common";
import {
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../@/components/ui/tooltip";

type TProps = {
  item: TNavIcon;
  pathname: string;
};

export const NavIcon = ({ item, pathname }: TProps) => {
  const Icon = item?.icon;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="relative py-1 text-sm">
            <div
              className={`${
                pathname?.split("/")?.filter((el) => el !== "")[0] ===
                  item.path?.replace("/", "") ||
                (pathname === ROUTES.DEFAULT && item.path === ROUTES.BIDS)
                  ? "bg-background"
                  : "opacity-80 hover:opacity-100"
              } cursor-pointer flex w-full items-center justify-between rounded-md duration-300 px-2 py-1 hover:bg-[#00000066]`}>
              <NavLink
                to={item.path}
                className="flex items-center gap-3 h-9 w-full shrink-0 min-w-[42px]">
                <span className="flex justify-center shrink-0 items-center w-7 h-7">
                  {typeof Icon === "string" ? (
                    <img src={Icon} alt={item.title} width={24} height={24} />
                  ) : (
                    <Icon />
                  )}
                </span>
                <div className="w-full leading-4">{item?.title}</div>
              </NavLink>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent
          side="right"
          className="hidden sm:flex py-2 h-[44px] items-center"
          sideOffset={17}>
          {item?.title}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
